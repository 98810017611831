const AlertPermissions = {
  // View
  VIEW_ALERT_TAB: 'AVA01',
  VIEW_ALERT_CAMERA_STREAMS: 'CRV01',
  VIEW_ALERT_DASHBOARD: 'ARD01',
  VIEW_ALERT_SUMMARY: 'ARS01',
  VIEW_ALERT_CONFIGURATION: 'ARC01',
  VIEW_ALERT_CONFIG: 'AVCC1',
  VIEW_ALERT_INVALID_ALERT: 'ALVIA1',
  ALERT_SUMMARY_ASSIGNEE_READ: 'alerts_summary__alert_assignee__read',
  AL_SUMMARY_VIEW_RAW_IMAGE: 'alert_summary__view_raw_image',
  READ_ALERT_STATUS: 'alert_status_read',
  READ_ALERT_ASSIGNEE: 'alert_assignee_read',
  READ_ALERT_MARKED_FOR_DELETION: 'alert_marked_for_deletion_read',

  // Write
  DOWNLOAD_ALERT_REPORT: 'ADR01',
  COMMENT_ALERT_SUMMARY: 'ACMT1',
  CHANGE_ALERT_STATUS: 'ACSS1',
  DRAW_ALERT_SUMMARY: 'ADRA1',
  TEST_ALERT_TELEGRAM: 'ATGT1',
  CONFIGURE_ALERT: 'AC002',
  CALIBRATE_ALERT: 'AC003',
  CONFIGURE_MANUAL_USE_CASES: 'AC004',
  CONFIGURE_NON_MANUAL_USE_CASES: 'AC005',
  ALERT_TRANSFER: 'ATRA1',
  DOWNLOAD_ALERT_PREVIEW: 'ADPR1',
  AL_SUMMARY_ASSIGNEE_WRITE: 'alerts_summary__alert_assignee__write',
  WRITE_ALERT_STATUS: 'alert_status_write',
  WRITE_ALERT_ASSIGNEE: 'alert_assignee_write',

  // UPDATE

  // DELETE
  DELETE_ALERTS: 'AD004',

  // ACTIONS
  WRITE_COMMENT_REPORT: 'AWRC1',
  AL_ROI_WRITE: 'camera_roi_write',
};

export default AlertPermissions;
