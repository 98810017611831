import { DateRange } from '@mui/x-date-pickers-pro';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { subWeeks } from 'date-fns';

import { COMMON_USER_FILTER_INITIAL_STATE } from 'components/common/UserFilterButton/useUserFiltersReducer';
import {
  UpdateUserFilters,
  UserFilters,
} from 'components/common/UserFilterAccordion/types';
import { AuditTrailChangesSectionEnum } from '../auditTrailChangesApi';

export type ResellerFilters = {
  resellerIds: string[];
  apnIds: string[];
};

export type UseCaseFilters = {
  siteIds: string[];
  groupIds: string[];
  cameraIds: string[];
};

type AuditTrailChangesFilterType = {
  performedByUserFilters: UserFilters;
  auditCategory: AuditTrailChangesSectionEnum;
  timeRange: DateRange<Date>;
  userSectionFilters: UserFilters;
  resellerFilters: ResellerFilters;
  useCasesFilters: UseCaseFilters;
};

const initialState: AuditTrailChangesFilterType = {
  auditCategory: 'changes_usecase',
  timeRange: [subWeeks(new Date(), 1), new Date()],
  userSectionFilters: {
    ...COMMON_USER_FILTER_INITIAL_STATE,
    users: [],
  },
  performedByUserFilters: {
    ...COMMON_USER_FILTER_INITIAL_STATE,
    users: [],
  },
  resellerFilters: {
    resellerIds: [],
    apnIds: [],
  },
  useCasesFilters: {
    siteIds: [],
    groupIds: [],
    cameraIds: [],
  },
};

const auditTrailChangesDashboardFilterSlice = createSlice({
  name: 'auditTrailChangesDashboardFilters',
  initialState,
  reducers: {
    updateTab(state, action: PayloadAction<AuditTrailChangesSectionEnum>) {
      state.auditCategory = action.payload;
    },
    updateUseCaseFilters(state, action: PayloadAction<UseCaseFilters>) {
      state.useCasesFilters = action.payload;
    },
    updateTimeRange(state, action: PayloadAction<DateRange<Date>>) {
      state.timeRange = action.payload;
    },
    updateUserSectionFilters(state, action: PayloadAction<UpdateUserFilters>) {
      // TODO : Use the conditional type for value based on field type
      state.userSectionFilters[action.payload.field] = action.payload
        .value as any;
    },

    updateResellerFilters(state, action: PayloadAction<ResellerFilters>) {
      state.resellerFilters = action.payload;
    },
    updatePerformedByUserFilters(
      state,
      action: PayloadAction<UpdateUserFilters>,
    ) {
      // TODO : Use the conditional type for value based on field type
      state.performedByUserFilters[action.payload.field] = action.payload
        .value as any;
    },
    resetCongregatedFilters(state) {
      state.useCasesFilters = initialState.useCasesFilters;
      state.userSectionFilters = initialState.userSectionFilters;
      state.resellerFilters = initialState.resellerFilters;
      state.performedByUserFilters = initialState.performedByUserFilters;
    },
  },
});

export const {
  updateTab,
  updateUseCaseFilters,
  updateTimeRange,
  updateUserSectionFilters,
  resetCongregatedFilters,
  updateResellerFilters,
  updatePerformedByUserFilters,
} = auditTrailChangesDashboardFilterSlice.actions;

export default auditTrailChangesDashboardFilterSlice.reducer;
