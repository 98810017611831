import { DateRange } from '@mui/x-date-pickers-pro';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  UpdateUserFilters,
  UserFilters,
} from 'components/common/UserFilterAccordion/types';
import { COMMON_USER_FILTER_INITIAL_STATE } from 'components/common/UserFilterButton/useUserFiltersReducer';
import { subWeeks } from 'date-fns';

import {
  ActionTypeEnum,
  AuditTrailActionCategoryEnum,
} from '../auditTrailActionsApi';

export type AlertsFilters = {
  siteGroupCamera: {
    siteIds: string[];
    groupIds: string[];
    cameraIds: string[];
  };
  actionType: ActionTypeEnum[];
};

type AuditTrailActionsFilterType = {
  userFilters: UserFilters;
  actionCategory: AuditTrailActionCategoryEnum;
  timeRange: DateRange<Date>;
  alertsFilters: AlertsFilters;
};

const initialState: AuditTrailActionsFilterType = {
  userFilters: {
    ...COMMON_USER_FILTER_INITIAL_STATE,
    users: [],
  },
  actionCategory: 'siteCamera',
  timeRange: [subWeeks(new Date(), 1), new Date()],
  alertsFilters: {
    siteGroupCamera: {
      siteIds: [],
      groupIds: [],
      cameraIds: [],
    },
    actionType: [],
  },
};

const auditTrailActionsDashboardFilterSlice = createSlice({
  name: 'auditTrailActionsDashboardFilters',
  initialState,
  reducers: {
    updateActionCategory(
      state,
      action: PayloadAction<AuditTrailActionCategoryEnum>,
    ) {
      state.actionCategory = action.payload;
    },
    updateUsers(state, action: PayloadAction<UpdateUserFilters>) {
      // TODO : Use the conditional type for value based on field type
      state.userFilters[action.payload.field] = action.payload.value as any;
    },
    updateTimeRange(state, action: PayloadAction<DateRange<Date>>) {
      state.timeRange = action.payload;
    },

    updateAlertsFilters(state, action: PayloadAction<AlertsFilters>) {
      state.alertsFilters = action.payload;
    },

    resetAggregatedFilters(state) {
      state.userFilters = initialState.userFilters;
      state.alertsFilters = initialState.alertsFilters;
    },
  },
});

export const {
  updateTimeRange,
  updateUsers,
  updateActionCategory,
  updateAlertsFilters,
  resetAggregatedFilters,
} = auditTrailActionsDashboardFilterSlice.actions;

export default auditTrailActionsDashboardFilterSlice.reducer;
