import { Section } from '../sections';

export const getRecursiveSubTabsPermission = (section: Section) => {
  const allPermissions: string[] = [];

  section.subTabs?.forEach((subTab) => {
    if (section.subTabs) {
      allPermissions.concat(getRecursiveSubTabsPermission(subTab));
    }

    allPermissions.push(subTab.permission);
  });

  return allPermissions;
};
