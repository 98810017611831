import React, { ReactElement, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import i18n from 'i18n';
import { FieldValues, UseFormGetValues, UseFormReturn } from 'react-hook-form';

import useFormHook, {
  UseFormHookProps,
} from 'libs/react-hook-form/useFormHook';
import { useConfirm } from 'contexts/ConfirmationDialogProvider';
import ButtonWithLoadingState from 'components/common/ButtonWithLoadingState';
import CustomDialog from './CustomDialog';
import { CustomDialogProps } from './CustomDialog/CustomDialog';
import Form from './Forms/Form';

interface IProps<FValues extends FieldValues>
  extends Omit<CustomDialogProps, 'maxWidth'> {
  children: (methods: UseFormReturn<FValues>) => React.ReactNode;
  onSubmit: (
    formValues: FValues,
    methods: UseFormReturn<FValues>,
  ) => Promise<FValues | void>;
  formId: string;
  secondaryActionButtonRender?: (
    getValues: UseFormGetValues<FValues>,
  ) => ReactElement | null;
  submitButtonText?: string;
  confirmCloseTitle?: string;
  confirmCloseDescription?: string;
  closeFormAfterSubmit?: boolean;
  useFormProps?: UseFormHookProps<FValues>;
  viewOnly?: boolean;
  submitButtonIsDisabled?: boolean;
}

const FormDialog = <FValues extends FieldValues>({
  children,
  onSubmit,
  formId,
  useFormProps,
  closeFormAfterSubmit,
  viewOnly,
  triggerButton,
  secondaryActionButtonRender,
  submitButtonText = i18n.t('common.save', 'Save', { ns: 'common' }),
  confirmCloseTitle = i18n.t('common.pendingChanges', 'Pending Changes', {
    ns: 'common',
  }),
  confirmCloseDescription = i18n.t(
    'common.haveUnsavedChangesWantToSave',
    'You have unsaved changes. Do you want to save them?',
    { ns: 'common' },
  ),
  submitButtonIsDisabled,
  ...dialogProps
}: IProps<FValues>) => {
  const { t } = useTranslation('common');
  const [isFormDialogOpen, setIsFormDialogOpen] = useState(dialogProps.isOpen);

  useEffect(() => {
    setIsFormDialogOpen(dialogProps.isOpen);
  }, [dialogProps.isOpen]);

  const methods = useFormHook<FValues>(useFormProps || {});

  const {
    formState: { isDirty, isSubmitting },
    reset,
  } = methods;

  const confirm = useConfirm();

  const handleCheckFormState = (close: () => void) => {
    if (isDirty && !viewOnly) {
      const onResolve = () => {
        methods.handleSubmit(async (val) => {
          await onSubmit(val, methods);
          close();
        })();
      };
      confirm(
        {
          title: confirmCloseTitle,
          description: confirmCloseDescription,
          confirmText: t('common.yes', 'Yes'),
          cancelText: t('common.no', 'No'),
        },
        onResolve,
        close,
      );
    } else close();
  };

  const handleFormDialogClose = () => {
    reset();
    setIsFormDialogOpen(false);
    if (dialogProps.closeDialog) dialogProps.closeDialog();
  };

  useEffect(() => {
    if (
      useFormProps?.defaultValues &&
      typeof useFormProps.defaultValues !== 'function'
    ) {
      reset(useFormProps.defaultValues);
    }
  }, [useFormProps?.defaultValues]);

  const handleFormSubmit = () => {
    methods.handleSubmit(async (formValues: FValues) => {
      const newValues = await onSubmit(formValues, methods);

      if (closeFormAfterSubmit) {
        handleFormDialogClose();
      } else if (newValues) {
        reset(newValues);
      }
    })();
  };

  useEffect(() => {
    if (dialogProps.isOpen) setIsFormDialogOpen(true);
  }, [dialogProps.isOpen]);

  const trigger =
    triggerButton &&
    React.cloneElement(triggerButton, {
      onClick: () => setIsFormDialogOpen(true),
    });

  return (
    <>
      {trigger}
      <CustomDialog
        {...dialogProps}
        confirmButton={
          viewOnly ? undefined : (
            <ButtonWithLoadingState
              onClick={handleFormSubmit}
              type="submit"
              disabled={!isDirty || isSubmitting || submitButtonIsDisabled}
              isLoading={isSubmitting}
              size="small"
              variant="contained"
              color="secondary"
            >
              {submitButtonText}
            </ButtonWithLoadingState>
          )
        }
        secondaryActionButton={
          secondaryActionButtonRender &&
          secondaryActionButtonRender(methods.getValues)
        }
        headerStyle="with-submit"
        actionBeforeClose={handleCheckFormState}
        noFooter
        maxWidth={false}
        isOpen={isFormDialogOpen}
        closeDialog={handleFormDialogClose}
      >
        <Form
          disableFormSubmit={!methods.formState.isDirty || isSubmitting}
          id={formId}
          onSubmit={handleFormSubmit}
          viewOnly={viewOnly}
        >
          {children(methods)}
        </Form>
      </CustomDialog>
    </>
  );
};

export default FormDialog;
