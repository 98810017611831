import React from 'react';

import { ListItemButton, ListItemText } from '@mui/material';
import { NavLink, useNavigate } from 'react-router-dom';
import { Item } from 'react-contexify';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import WithPermissions from 'components/common/Permissions';
import { usePlatformConfigContext } from 'contexts/PlatformConfigContext';
import AuthorisedRoutes from '../../../../AuthorisedRoutes';
import { Section } from '../sections';
import useGetCurrentSection from '../hooks/useGetSection';

type IAppbarLinkProps = Pick<
  Section,
  'name' | 'path' | 'tab_access' | 'permission'
> & {
  isMenuLink?: boolean;
  isSubTabSelected?: boolean;
  hasSubMenus?: boolean;
};

const AppbarNavLink = ({
  name,
  tab_access,
  path,
  permission,
  isMenuLink,
  isSubTabSelected,
  hasSubMenus,
}: IAppbarLinkProps) => {
  const section = useGetCurrentSection('section');
  const navigate = useNavigate();
  const platformConfig = usePlatformConfigContext();

  return (
    <WithPermissions permission={permission}>
      <AuthorisedRoutes tab_name={tab_access} Appbar>
        {isMenuLink ? (
          <Item
            onClick={() => navigate(path)}
            style={{
              background:
                section === path
                  ? platformConfig.navbar_hover_color
                  : 'transparent',
            }}
          >
            <ListItemText sx={{ color: 'white' }} primary={name} />
          </Item>
        ) : (
          <NavLink
            to={path}
            style={({ isActive }) => ({
              display: 'block',
              background:
                isActive || isSubTabSelected
                  ? platformConfig.navbar_hover_color
                  : 'transparent',
            })}
            onClick={(e) => {
              if (hasSubMenus) e.preventDefault();
            }}
          >
            <ListItemButton>
              <ListItemText primary={name} />
              {hasSubMenus && <KeyboardArrowDownIcon />}
            </ListItemButton>
          </NavLink>
        )}
      </AuthorisedRoutes>
    </WithPermissions>
  );
};

export default AppbarNavLink;
