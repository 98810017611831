import React from 'react';

import { Box } from '@mui/material';

import { Section } from '../sections';
import WithPermissions from '../../Permissions';
import { getRecursiveSubTabsPermission } from './utils';
import AppbarMenu from './AppbarMenu';
import AppbarNavLink from './AppbarLink';
import useIsNavLinkSelected from './useIsNavLinkSelected';

interface IProps {
  section: Section;
}

const AppbarMenuItem = ({ section }: IProps) => {
  if (section.subTabs?.length) {
    const isSubTabSelected = useIsNavLinkSelected()(section);

    return (
      <WithPermissions permission={getRecursiveSubTabsPermission(section)}>
        <AppbarMenu
          anchorEl={
            <Box>
              <AppbarNavLink
                hasSubMenus
                {...section}
                isSubTabSelected={isSubTabSelected}
              />
            </Box>
          }
          sections={section.subTabs}
          id={section.id}
        />
      </WithPermissions>
    );
  }

  return <AppbarNavLink {...section} />;
};

export default AppbarMenuItem;
