import emptySplitApi from 'services/api/ailyssaBaseApi';

// eslint-disable-next-line
import {
  SitesListApiResponse,
  SitesListApiArg,
  SitesCreateApiResponse,
  SitesCreateApiArg,
  SitesRetrieveApiResponse,
  SitesRetrieveApiArg,
  SitesUpdateApiResponse,
  SitesUpdateApiArg,
  SitesPartialUpdateApiResponse,
  SitesPartialUpdateApiArg,
  SitesDestroyApiResponse,
  SitesDestroyApiArg,
  SitesVmSummaryReportDownloadCreateApiResponse,
  SitesVmSummaryReportDownloadCreateApiArg,
  SitesVaPeriodicReportTestCreateApiResponse,
  SitesVaPeriodicReportTestCreateApiArg,
  SitesGroupsListApiResponse,
  SitesGroupsListApiArg,
  SitesGroupsCreateApiResponse,
  SitesGroupsCreateApiArg,
  SitesGroupsRetrieveApiResponse,
  SitesGroupsRetrieveApiArg,
  SitesGroupsUpdateApiResponse,
  SitesGroupsUpdateApiArg,
  SitesGroupsPartialUpdateApiResponse,
  SitesGroupsPartialUpdateApiArg,
  SitesGroupsDestroyApiResponse,
  SitesGroupsDestroyApiArg,
  SitesGroupsGroupedListApiResponse,
  SitesGroupsGroupedListApiArg,
  SitesCheckUseCaseStatusCreateApiArg,
  SitesCheckUseCaseStatusCreateApiResponse,
  SitesDisableUseCaseCreateApiResponse,
  SitesDisableUseCaseCreateApiArg,
  SitesGetUseCaseStatusesRetrieveApiArg,
  SitesGetUseCaseStatusesRetrieveApiResponse,
} from './types';

const sitesApi = emptySplitApi.injectEndpoints({
  endpoints: (build) => ({
    sitesList: build.query<SitesListApiResponse, SitesListApiArg>({
      query: (queryArg) => ({
        url: '/sites/',
        params: {
          companies: queryArg.companies,
          tab: queryArg.tab,
        },
      }),
      providesTags: ['Site'],
    }),
    sitesCreate: build.mutation<SitesCreateApiResponse, SitesCreateApiArg>({
      query: (queryArg) => ({
        url: '/sites/',
        method: 'POST',
        body: queryArg.site,
      }),
      invalidatesTags: ['Site', 'User'],
    }),
    sitesRetrieve: build.query<SitesRetrieveApiResponse, SitesRetrieveApiArg>({
      query: (queryArg) => ({ url: `/sites/${queryArg.id}/` }),
      keepUnusedDataFor: 5,
      providesTags: (result) =>
        result ? [{ type: 'Site', id: result.id }] : ['Site'],
    }),
    sitesUpdate: build.mutation<SitesUpdateApiResponse, SitesUpdateApiArg>({
      query: (queryArg) => ({
        url: `/sites/${queryArg.id}/`,
        method: 'PUT',
        body: queryArg.site,
      }),
      invalidatesTags: ['Site', 'User', 'CamerasLabelsList'],
    }),
    sitesPartialUpdate: build.mutation<
      SitesPartialUpdateApiResponse,
      SitesPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/sites/${queryArg.id}/`,
        method: 'PATCH',
        body: queryArg.patchedSite,
      }),
      invalidatesTags: ['User', 'CamerasLabelsList'],
    }),
    sitesDestroy: build.mutation<SitesDestroyApiResponse, SitesDestroyApiArg>({
      query: (queryArg) => ({
        url: `/sites/${queryArg.id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Site', 'User'],
    }),
    sitesVmSummaryReportDownloadCreate: build.mutation<
      SitesVmSummaryReportDownloadCreateApiResponse,
      SitesVmSummaryReportDownloadCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/sites/${queryArg.id}/vm_summary_report_download/`,
        method: 'POST',
        body: queryArg.vehiclesReportDownloadRequest,
      }),
    }),
    sitesVaPeriodicReportTestCreate: build.mutation<
      SitesVaPeriodicReportTestCreateApiResponse,
      SitesVaPeriodicReportTestCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/sites/${queryArg.id}/va_periodic_report_test/`,
        method: 'POST',
      }),
    }),
    sitesGroupsList: build.query<
      SitesGroupsListApiResponse,
      SitesGroupsListApiArg
    >({
      query: (queryArg) => ({
        url: '/sites/groups/',
        params: { sites: queryArg.sites },
      }),
      providesTags: ['Group'],
    }),
    sitesGroupsCreate: build.mutation<
      SitesGroupsCreateApiResponse,
      SitesGroupsCreateApiArg
    >({
      query: (queryArg) => ({
        url: '/sites/groups/',
        method: 'POST',
        body: queryArg.siteGroup,
      }),
      invalidatesTags: ['Group'],
    }),
    sitesGroupsRetrieve: build.query<
      SitesGroupsRetrieveApiResponse,
      SitesGroupsRetrieveApiArg
    >({
      query: (queryArg) => ({ url: `/sites/groups/${queryArg.id}/` }),
      providesTags: ['Group'],
      keepUnusedDataFor: 5,
    }),
    sitesGroupsUpdate: build.mutation<
      SitesGroupsUpdateApiResponse,
      SitesGroupsUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/sites/groups/${queryArg.id}/`,
        method: 'PUT',
        body: queryArg.siteGroup,
      }),
      invalidatesTags: ['Group'],
    }),
    sitesGroupsPartialUpdate: build.mutation<
      SitesGroupsPartialUpdateApiResponse,
      SitesGroupsPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/sites/groups/${queryArg.id}/`,
        method: 'PATCH',
        body: queryArg.patchedSiteGroup,
      }),
    }),
    sitesGroupsDestroy: build.mutation<
      SitesGroupsDestroyApiResponse,
      SitesGroupsDestroyApiArg
    >({
      query: (queryArg) => ({
        url: `/sites/groups/${queryArg.id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Group'],
    }),
    sitesGroupsGroupedList: build.query<
      SitesGroupsGroupedListApiResponse,
      SitesGroupsGroupedListApiArg
    >({
      query: () => ({ url: '/sites/groups/grouped/' }),
    }),
    sitesCheckUseCaseStatusCreate: build.mutation<
      SitesCheckUseCaseStatusCreateApiResponse,
      SitesCheckUseCaseStatusCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/sites/${queryArg.id}/check_use_case_status/`,
        method: 'POST',
        body: queryArg.checkUseCaseStatusRequest,
      }),
    }),
    sitesDisableUseCaseCreate: build.mutation<
      SitesDisableUseCaseCreateApiResponse,
      SitesDisableUseCaseCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/sites/${queryArg.id}/disable_use_case/`,
        method: 'POST',
        body: queryArg.disableUseCaseRequest,
      }),
    }),
    sitesGetUseCaseStatusesRetrieve: build.query<
      SitesGetUseCaseStatusesRetrieveApiResponse,
      SitesGetUseCaseStatusesRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/sites/${queryArg.id}/get_use_case_statuses/`,
      }),
    }),
  }),
});

export const {
  useSitesListQuery,
  useSitesCreateMutation,
  useSitesRetrieveQuery,
  useSitesUpdateMutation,
  useSitesPartialUpdateMutation,
  useSitesDestroyMutation,
  useSitesVmSummaryReportDownloadCreateMutation,
  useSitesVaPeriodicReportTestCreateMutation,
  useSitesGroupsListQuery,
  useSitesGroupsCreateMutation,
  useSitesGroupsRetrieveQuery,
  useSitesGroupsUpdateMutation,
  useSitesGroupsPartialUpdateMutation,
  useSitesGroupsDestroyMutation,
  useSitesGroupsGroupedListQuery,
  useSitesCheckUseCaseStatusCreateMutation,
  useSitesDisableUseCaseCreateMutation,
  useSitesGetUseCaseStatusesRetrieveQuery,
} = sitesApi;

export default sitesApi;
export * from './types';
