import React, { useState } from 'react';

import { Box, Button, Grid, Typography, Popover } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useTranslation } from 'react-i18next';

import ColumnAndSubColumns, {
  ColumnAndSubColumnProps,
} from './ColumnAndSubColumns/ColumnAndSubColumns';
import { Column } from './ColumnAndSubColumns/types';
import ButtonWithLoadingState from './ButtonWithLoadingState';

interface IProps extends Omit<ColumnAndSubColumnProps, 'columns'> {
  heading?: string;
  btnText?: string;
  columns: Column[] | undefined;
  noClearAll?: boolean;
  isLoading?: boolean;
  triggerBtn?: JSX.Element;
  disabled?: boolean;
}

const FilterCameras = ({
  heading,
  columns,
  btnText,
  noClearAll,
  isLoading,
  triggerBtn,
  disabled,
  ...columnAndSubColumnsProps
}: IProps) => {
  const { t } = useTranslation('common');
  if (!heading) heading = t('cameras', 'Cameras');

  const { selectedItems, setSelectedItems, ...otherProps } =
    columnAndSubColumnsProps;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpenFilterMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseFilterMenu = () => setAnchorEl(null);

  const isActive = Object.entries(selectedItems).some(
    ([, val]) => val.length > 0,
  );

  const handleResetFilter = () => {
    setSelectedItems({
      column: [],
      subColOne: [],
      subColTwo: [],
    });
  };

  return (
    <Box
      sx={
        disabled
          ? {
              pointerEvents: 'none',
            }
          : {}
      }
    >
      <Box width="max-content" onClick={handleOpenFilterMenu}>
        {triggerBtn || (
          <ButtonWithLoadingState
            size="medium"
            variant={isActive || isLoading ? 'contained' : 'outlined'}
            sx={{ textTransform: 'capitalize' }}
            isLoading={Boolean(isLoading)}
            disabled={disabled}
          >
            {btnText || heading}
            <KeyboardArrowDownIcon />
          </ButtonWithLoadingState>
        )}
      </Box>
      {columns && (
        <Popover
          sx={{ zIndex: '1500' }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={!!anchorEl}
          anchorEl={anchorEl}
          onClose={handleCloseFilterMenu}
        >
          <Box
            sx={{
              width: '32rem',
              height: '20rem',
            }}
          >
            <Grid
              sx={{ padding: '8px 16px' }}
              justifyContent="space-between"
              container
            >
              <Grid item>
                <Typography variant="h6">{heading}</Typography>
              </Grid>
              {!noClearAll && (
                <Grid item>
                  <Button
                    disabled={!isActive}
                    variant="contained"
                    onClick={handleResetFilter}
                    size="small"
                    sx={{ width: '100%', display: 'block' }}
                    color="warning"
                  >
                    {t('common.clearAll', 'Clear All')}
                  </Button>
                </Grid>
              )}
            </Grid>
            <Box sx={{ height: 'calc(100% - 50px)' }}>
              <ColumnAndSubColumns
                setSelectedItems={setSelectedItems}
                selectedItems={selectedItems}
                columns={columns}
                {...otherProps}
              />
            </Box>
          </Box>
        </Popover>
      )}
    </Box>
  );
};

export default FilterCameras;
