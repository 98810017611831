import { CommonUserFilters, UserGroup } from 'ts_shared';

export enum CommonUserFilterActionKind {
  UPDATE_FIELD = 'UPDATE_FIELD',
  RESET_FILTERS = 'RESET_FILTERS',
}

type CommonUserFilterUpdateFieldAction =
  | {
      type: CommonUserFilterActionKind.UPDATE_FIELD;
      fieldName: 'groups';
      payload: UserGroup[];
    }
  | {
      type: CommonUserFilterActionKind.UPDATE_FIELD;
      fieldName: Exclude<keyof CommonUserFilters, 'groups'>;
      payload: string;
    };

export type UserFilterActionTypes =
  | CommonUserFilterUpdateFieldAction
  | { type: CommonUserFilterActionKind.RESET_FILTERS };
