import emptySplitApi from 'services/api/ailyssaBaseApi';
import {
  ResellersListApiResponse,
  ResellersListApiArg,
  ResellersCreateApiResponse,
  ResellersCreateApiArg,
  ResellersRetrieveApiResponse,
  ResellersRetrieveApiArg,
  ResellersUpdateApiResponse,
  ResellersUpdateApiArg,
  ResellersPartialUpdateApiResponse,
  ResellersPartialUpdateApiArg,
  ResellersDestroyApiResponse,
  ResellersDestroyApiArg,
  ResellersUsersListApiArg,
  ResellersUsersListApiResponse,
  ResellersRolesListApiArg,
  ResellersRolesListApiResponse,
  ResellerDeploymentsGetConfigRetrieveApiArg,
  ResellerDeploymentsGetConfigRetrieveApiResponse,
  ResellersGroupCompaniesListApiArg,
  ResellersGroupCompaniesListApiResponse,
  ResellersGroupAywaProcessingNodesListApiResponse,
  ResellersGroupAywaProcessingNodesListApiArg,
  ResellersMonthlyUsageRetrieveApiResponse,
  ResellersMonthlyUsageRetrieveApiArg,
  ResellersCurrentStatsRetrieveApiResponse,
  ResellersCurrentStatsRetrieveApiArg,
} from './types';

const partnersApi = emptySplitApi.injectEndpoints({
  endpoints: (build) => ({
    resellersList: build.query<ResellersListApiResponse, ResellersListApiArg>({
      query: () => ({ url: '/resellers/' }),
      providesTags: ['Reseller'],
    }),
    resellersCreate: build.mutation<
      ResellersCreateApiResponse,
      ResellersCreateApiArg
    >({
      query: (queryArg) => ({
        url: '/resellers/',
        method: 'POST',
        body: queryArg.reseller,
      }),
      invalidatesTags: [
        'Reseller',
        'ResellerAywaProcessingNodes',
        'ResellerGroupCompany',
      ],
    }),
    resellersRetrieve: build.query<
      ResellersRetrieveApiResponse,
      ResellersRetrieveApiArg
    >({
      query: (queryArg) => ({ url: `/resellers/${queryArg.id}/` }),
      providesTags: (result) =>
        result ? [{ type: 'Reseller', id: result.id }] : ['Reseller'],
    }),
    resellersUpdate: build.mutation<
      ResellersUpdateApiResponse,
      ResellersUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/resellers/${queryArg.id}/`,
        method: 'PUT',
        body: queryArg.reseller,
      }),
      invalidatesTags: [
        'Reseller',
        'ResellerAywaProcessingNodes',
        'ResellerGroupCompany',
      ],
    }),
    resellersPartialUpdate: build.mutation<
      ResellersPartialUpdateApiResponse,
      ResellersPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/resellers/${queryArg.id}/`,
        method: 'PATCH',
        body: queryArg.patchedReseller,
      }),
      invalidatesTags: [
        'Reseller',
        'ResellerAywaProcessingNodes',
        'ResellerGroupCompany',
      ],
    }),
    resellersDestroy: build.mutation<
      ResellersDestroyApiResponse,
      ResellersDestroyApiArg
    >({
      query: (queryArg) => ({
        url: `/resellers/${queryArg.id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: [
        'Reseller',
        'ResellerAywaProcessingNodes',
        'ResellerGroupCompany',
      ],
    }),
    resellersUsersList: build.query<
      ResellersUsersListApiResponse,
      ResellersUsersListApiArg
    >({
      query: (queryArg) => ({
        url: `/resellers/${queryArg.id}/users/`,
        params: {
          first_name:
            queryArg.firstName.length > 0 ? queryArg.firstName : undefined,
          last_name:
            queryArg.lastName.length > 0 ? queryArg.lastName : undefined,
          email: queryArg.email.length > 0 ? queryArg.email : undefined,
          username:
            queryArg.username.length > 0 ? queryArg.username : undefined,
          groups: queryArg.groups,
          user_channel_id: queryArg.userChannelId || undefined,
        },
      }),
      providesTags: ['ResellerUser'],
    }),
    resellersGroupAywaProcessingNodesList: build.query<
      ResellersGroupAywaProcessingNodesListApiResponse,
      ResellersGroupAywaProcessingNodesListApiArg
    >({
      query: () => ({ url: `/resellers/group_apns/` }),
      providesTags: ['ResellerAywaProcessingNodes'],
    }),
    resellersRolesList: build.query<
      ResellersRolesListApiResponse,
      ResellersRolesListApiArg
    >({
      query: () => ({ url: '/resellers/roles/' }),
    }),
    resellerDeploymentsGetConfigRetrieve: build.query<
      ResellerDeploymentsGetConfigRetrieveApiResponse,
      ResellerDeploymentsGetConfigRetrieveApiArg
    >({
      query: () => ({ url: '/reseller_deployments/get_config/' }),
    }),
    resellersGroupCompaniesList: build.query<
      ResellersGroupCompaniesListApiResponse,
      ResellersGroupCompaniesListApiArg
    >({
      query: () => ({ url: '/resellers/group_companies/' }),
      providesTags: ['ResellerGroupCompany'],
    }),
    resellersMonthlyUsageRetrieve: build.query<
      ResellersMonthlyUsageRetrieveApiResponse,
      ResellersMonthlyUsageRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/resellers/${queryArg.id}/monthly_usage/`,
        params: {
          month: queryArg.month,
          year: queryArg.year,
        },
      }),
    }),
    resellersCurrentStatsRetrieve: build.query<
      ResellersCurrentStatsRetrieveApiResponse,
      ResellersCurrentStatsRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/resellers/${queryArg.id}/current_stats/`,
      }),
    }),
  }),
});

export const {
  useResellersListQuery,
  useResellersCreateMutation,
  useResellersRetrieveQuery,
  useResellersUpdateMutation,
  useResellersPartialUpdateMutation,
  useResellersUsersListQuery,
  useResellersRolesListQuery,
  useResellersDestroyMutation,
  useResellerDeploymentsGetConfigRetrieveQuery,
  useResellersGroupCompaniesListQuery,
  useResellersGroupAywaProcessingNodesListQuery,
  useResellersMonthlyUsageRetrieveQuery,
  useResellersCurrentStatsRetrieveQuery,
} = partnersApi;
export * from './types';
