import * as Yup from 'yup';

const initYupLocale = () =>
  Yup.setLocale({
    mixed: {
      required: 'This field is required',
      notType: ({ type }) => `Value must be ${type} type`,
    },
    number: {
      min: ({ min }) => `Value must be greater than or equal to ${min}`,
      max: ({ max }) => `Value must be less than or equal to ${max}`,
      positive: 'Only positive numbers are allowed',
      integer: 'Only integer values are allowed',
    },
  });

export const passwordValidation = Yup.string()
  .required()
  .min(12)
  .matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}[\]:;<>,.?~\\/-]).{8,}$/,
    'Password must contain at least one lowercase letter, one uppercase letter, one number, and one special character',
  );

export const jsonValidation = () =>
  Yup.string().test('is-json', 'Invalid JSON format', (value) => {
    if (!value) return true;
    try {
      JSON.parse(value);
      return true;
    } catch (e) {
      return false;
    }
  });

export default initYupLocale;
