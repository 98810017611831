import useGetCurrentSection from '../hooks/useGetSection';
import { Section } from '../sections';

const useIsNavLinkSelected = () => {
  const curSection = useGetCurrentSection('section');

  const isNavLinkSelected = (section: Section) => {
    let isSelected = false;

    section.subTabs?.forEach((subTab) => {
      if (isSelected) return;

      if (subTab.subTabs) {
        isSelected = isNavLinkSelected(subTab);
        return;
      }
      isSelected = subTab.path === curSection;
    });

    return isSelected;
  };

  return isNavLinkSelected;
};

export default useIsNavLinkSelected;
