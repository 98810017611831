/* eslint import/no-cycle: [0] */
import { configureStore, createListenerMiddleware } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import auditTrailActionsFilterReducer from 'tabs/auditTrailActions/slices/auditTrailActionsDashboardFilterSlice';
import auditTrailChangesFilterReducer from 'tabs/auditTrailChanges/slices/auditTrailChangesDashboardFilterSlice';
import footfallFilterSliceReducer from 'tabs/footfall/slices/footfallDashboardFiltersSlice';
import dateRange2FilterSliceReducer from 'services/filters/dateRange2/dateRangeFilterSlice';
import operationsFilterReducer from 'services/filters/operationsFilter';
import zoneFiltersSliceReducer from 'tabs/facialRecognition/frSlices/frFilterSlice';
import workhourFiltersSliceReducer from 'tabs/workhourTracking/slices/workhourDashboardFiltersSlice';
import vehicleManagementDashboardReducer from 'tabs/vehicleManagement/slices/vehicleManagementDashboardFilterSlice/vehicleManagementDashboardFilterSlice';
import captureLogsDashboardReducer from 'tabs/captureLogs/slices/captureLogsDashboardFilterSlice';
import accessControlDashboardReducer from 'tabs/accessControl/slices/accessControlDashboardFilterSlice';
import { injectedRtkApi } from '../services/api/ailyssaApi';
import sitesFilterReducer from '../services/filters/sites';
import triggersFilterReducer from '../services/filters/triggers';
import groupsFilterReducer from '../services/filters/groups';
import statusesFilterReducer from '../services/filters/alertStatuses';
import camerasFilterReducer from '../services/filters/cameras';
import followedBookmarkedReducer from '../services/filters/alertFollowBookmark/alertFollowBookmarkSlice';
import assigneesFilterReducer from '../services/filters/alertAssignees';

const listenerMiddleware = createListenerMiddleware();

// listenerMiddleware.startListening({
//   actionCreator: updateSelection,
//   effect: async (action, listenerApi) => {
//     listenerApi.dispatch(groupUpdateSelection([]));
//     listenerApi.cancelActiveListeners();
//   },
// });

const store = configureStore({
  devTools: true,
  reducer: {
    [injectedRtkApi.reducerPath]: injectedRtkApi.reducer,
    sitesFilter: sitesFilterReducer,
    groupsFilter: groupsFilterReducer,
    triggersFilter: triggersFilterReducer,
    operationsFilter: operationsFilterReducer,
    camerasFilter: camerasFilterReducer,
    statusesFilter: statusesFilterReducer,
    frFilters: zoneFiltersSliceReducer,
    dateRange2: dateRange2FilterSliceReducer,
    footfallFilter: footfallFilterSliceReducer,
    workhourFilter: workhourFiltersSliceReducer,
    followedBookmarkedFilter: followedBookmarkedReducer,
    vehicleManagementDashboardFilter: vehicleManagementDashboardReducer,
    assigneeFilter: assigneesFilterReducer,
    captureLogsDashboardFilter: captureLogsDashboardReducer,
    auditTrailChangesDashboardFilter: auditTrailChangesFilterReducer,
    auditTrailActionsDashboardFilter: auditTrailActionsFilterReducer,
    accessControlDashboardFilter: accessControlDashboardReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      injectedRtkApi.middleware,
      listenerMiddleware.middleware,
    ),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
