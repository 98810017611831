// This custom hook listens for the events emitted from server when the download of the report is completed.
// It shows a snackbar notification if the download fails and opens the download link in a new tab if the download is successful.

import { useEffect } from 'react';

import { useSnackbar } from 'notistack';
import { useSocketContext } from 'socket';

const message = 'download_ready';

type DownloadReportResponseType = {
  resource: string;
  error: string;
  download_links: string[];
};

const useDownloadReportSocketListener = () => {
  const { enqueueSnackbar } = useSnackbar();
  const socketCtx = useSocketContext();

  const handleDownloadReports = (resp: DownloadReportResponseType) => {
    if ('error' in resp && resp.error) {
      enqueueSnackbar(`Failed to download ${resp.resource}`, {
        variant: 'error',
      });
      return;
    }
    resp.download_links.forEach((reportLink) => window.open(reportLink));
  };

  useEffect(() => {
    socketCtx.on(message, handleDownloadReports);

    return () => {
      socketCtx.off(message, handleDownloadReports);
    };
  }, [message, socketCtx]);
};

export default useDownloadReportSocketListener;
