import { skipToken } from '@reduxjs/toolkit/query';
import {
  useUsersCurrentRetrieveQuery,
  useUsersRetrieveQuery,
} from 'services/api/general';

type UseRetrieveUserProps = {
  getOwnUserDetail?: boolean;
  userId?: number;
};

const useRetrieveUser = ({
  userId,
  getOwnUserDetail,
}: UseRetrieveUserProps) => {
  const { data: curUserData } = useUsersCurrentRetrieveQuery();

  const { data } = useUsersRetrieveQuery(userId ? { id: userId } : skipToken);

  return {
    user: getOwnUserDetail ? curUserData : data,
    isCurUser: userId === curUserData?.id,
  };
};

export default useRetrieveUser;
