import React from 'react';

import { Box, SxProps } from '@mui/material';

interface IProps {
  viewOnly?: boolean;
  children: React.ReactNode;
  styles?: SxProps;
}

const FieldSet = ({ children, viewOnly, styles }: IProps) => (
  <Box
    sx={{
      border: '0',
      padding: '0',
      // The input fields and buttons that appear inside viewOnly form should be disabled and grayed out
      '.MuiInputBase-root, .MuiFormControlLabel-root, button': viewOnly
        ? {
            // MUI Select component is completely ignoring fieldset disabled. So, had to do this for workaround.
            pointerEvents: 'none !important',
            opacity: '.6',
          }
        : '',
      minWidth: 'unset',
      width: '100%',
      height: '100%',
      ...styles,
    }}
    component="fieldset"
    disabled={viewOnly}
  >
    {children}
  </Box>
);

export default FieldSet;
