import { AnnotationLabel, Resources, UsecaseLabelEnum } from 'ts_shared';

import emptySplitApi from './ailyssaBaseApi';
import { TabsEnum } from './general/usersApi';

const injectedRtkApi = emptySplitApi.injectEndpoints({
  endpoints: (build) => ({
    getDocsSchemaOpenapiJson: build.query<
      GetDocsSchemaOpenapiJsonApiResponse,
      GetDocsSchemaOpenapiJsonApiArg
    >({
      query: (queryArg) => ({
        url: '/docs/schema/openapi.json',
        params: { lang: queryArg.lang },
      }),
    }),
    livenessRetrieve: build.query<
      LivenessRetrieveApiResponse,
      LivenessRetrieveApiArg
    >({
      query: () => ({ url: '/liveness/' }),
    }),
    sitesSiteSiteGroupings: build.query<
      SitesGetFrZoneInfoRetrieveApiResponse,
      SitesGetFrZoneInfoRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: '/sites/site_groupings/',
        params: {
          usecase: queryArg?.usecaseLabel,
          tab: queryArg?.tab,
          reseller_id: queryArg?.resellerId,
        },
      }),
      providesTags: ['Camera', 'Site', 'Group'],
    }),
    s3SignedRetrieve: build.query<
      S3SignedRetrieveApiResponse,
      S3SignedRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: '/s3/signed/',
        params: { context: queryArg.context, filename: queryArg.filename },
      }),
    }),

    telegramTestCreate: build.mutation<
      TelegramTestCreateApiResponse,
      TelegramTestCreateApiArg
    >({
      query: (queryArg) => ({
        url: '/telegram/test/',
        method: 'POST',
        body: queryArg.telegramVerifyRequest,
      }),
    }),
  }),
  overrideExisting: false,
});

export type FrRecordsDestroyApiResponse = unknown;
export type FrRecordsDestroyApiArg = {
  id: string;
};

export { injectedRtkApi as ailyssaApi };

export type GetDocsSchemaOpenapiJsonApiResponse = /** status 200  */ {
  [key: string]: any;
};
export type GetDocsSchemaOpenapiJsonApiArg = {
  lang?:
    | 'af'
    | 'ar'
    | 'ar-dz'
    | 'ast'
    | 'az'
    | 'be'
    | 'bg'
    | 'bn'
    | 'br'
    | 'bs'
    | 'ca'
    | 'cs'
    | 'cy'
    | 'da'
    | 'de'
    | 'dsb'
    | 'el'
    | 'en'
    | 'en-au'
    | 'en-gb'
    | 'eo'
    | 'es'
    | 'es-ar'
    | 'es-co'
    | 'es-mx'
    | 'es-ni'
    | 'es-ve'
    | 'et'
    | 'eu'
    | 'fa'
    | 'fi'
    | 'fr'
    | 'fy'
    | 'ga'
    | 'gd'
    | 'gl'
    | 'he'
    | 'hi'
    | 'hr'
    | 'hsb'
    | 'hu'
    | 'hy'
    | 'ia'
    | 'id'
    | 'ig'
    | 'io'
    | 'is'
    | 'it'
    | 'ja'
    | 'ka'
    | 'kab'
    | 'kk'
    | 'km'
    | 'kn'
    | 'ko'
    | 'ky'
    | 'lb'
    | 'lt'
    | 'lv'
    | 'mk'
    | 'ml'
    | 'mn'
    | 'mr'
    | 'ms'
    | 'my'
    | 'nb'
    | 'ne'
    | 'nl'
    | 'nn'
    | 'os'
    | 'pa'
    | 'pl'
    | 'pt'
    | 'pt-br'
    | 'ro'
    | 'ru'
    | 'sk'
    | 'sl'
    | 'sq'
    | 'sr'
    | 'sr-latn'
    | 'sv'
    | 'sw'
    | 'ta'
    | 'te'
    | 'tg'
    | 'th'
    | 'tk'
    | 'tr'
    | 'tt'
    | 'udm'
    | 'uk'
    | 'ur'
    | 'uz'
    | 'vi'
    | 'zh-hans'
    | 'zh-hant';
};

export type LivenessRetrieveApiResponse = /** status 200  */ Blob;
export type LivenessRetrieveApiArg = void;

export type SitesGetFrZoneInfoRetrieveApiResponse =
  /** status 200  */ Resources[];
export type SitesGetFrZoneInfoRetrieveApiArg = {
  resellerId?: string;
  usecaseLabel?: UsecaseLabelEnum;
  tab?: TabsEnum;
} | void;

export type S3SignedRetrieveApiResponse = /** status 200  */ S3SignedUploadUrl;
export type S3SignedRetrieveApiArg = {
  /** The context (label/description) of the uploaded file */
  context:
    | 'alert_csv'
    | 'alert_frame'
    | 'alert_video'
    | 'block_plans'
    | 'fr_mugshot'
    | 'fr_snapshot'
    | 'logo'
    | 'reports'
    | 'vehicle_body'
    | 'vehicle_plate'
    | 'resellers'
    | 'avatar'
    | 'comment_attachments'
    | 'people_management_mugshots';
  /** The name of the file to upload to S3 */
  filename: string;
};

export type TelegramTestCreateApiResponse = /** status 200  */ Blob;
export type TelegramTestCreateApiArg = {
  telegramVerifyRequest: TelegramVerifyRequest;
};

export interface Ellipse extends AnnotationLabel {
  color?: string | null;
  user?: string | null;
  x: number;
  y: number;
  radiusY: number;
  radiusX: number;
}
export interface Rectangle extends AnnotationLabel {
  color?: string | null;
  user?: string | null;
  x: number;
  y: number;
  height: number;
  width: number;
}
export interface Polygon extends AnnotationLabel {
  color?: string | null;
  user?: string | null;
  points: number[][];
}
export interface FreeLine extends AnnotationLabel {
  color?: string | null;
  user?: string | null;
  points: number[][];
}

export interface MultiLine extends Polygon {}

export type BaseCamera = {
  id: string;
  name: string;
};
export type ResourcesZones = {
  id: string;
  name: string;
};

export type S3SignedUploadUrl = {
  key: string;
  url: string;
  download_url: string;
};

export type TelegramVerifyRequest = {
  telegram_id: string;
  telegram_token: string;
  site_id?: string;
  group_id?: string;
  camera_id?: string;
};

export type PaginatedList<Result> = {
  count: number;
  next: string | null;
  previous: string | null;
  results: Result[];
  total_pages: number;
};

export const {
  useGetDocsSchemaOpenapiJsonQuery,
  useLivenessRetrieveQuery,
  useSitesSiteSiteGroupingsQuery,
  useS3SignedRetrieveQuery,
  useLazyS3SignedRetrieveQuery,
  useTelegramTestCreateMutation,
} = injectedRtkApi;

export { injectedRtkApi };
