import { useReducer } from 'react';

import { CommonUserFilters } from 'ts_shared';
import { CommonUserFilterActionKind, UserFilterActionTypes } from './types';

export const COMMON_USER_FILTER_INITIAL_STATE: CommonUserFilters = {
  firstName: '',
  lastName: '',
  email: '',
  username: '',
  groups: [],
  userChannelId: '',
};

const reducer = (state: CommonUserFilters, action: UserFilterActionTypes) => {
  switch (action.type) {
    case CommonUserFilterActionKind.UPDATE_FIELD:
      return { ...state, [action.fieldName]: action.payload };
    case CommonUserFilterActionKind.RESET_FILTERS:
      return COMMON_USER_FILTER_INITIAL_STATE;
    default:
      return state;
  }
};

const useUserFiltersReducer = () =>
  useReducer(reducer, COMMON_USER_FILTER_INITIAL_STATE);

export default useUserFiltersReducer;
