import { useSitesSiteSiteGroupingsQuery } from 'services/api';
import { TabsEnum } from 'services/api/general';

interface IArgs {
  tab?: TabsEnum;
  resellerId?: string;
}

const useConvertCamerasToSubCols = (args?: IArgs) => {
  const { data, isLoading } = useSitesSiteSiteGroupingsQuery(args);

  const camerasData = data?.map((site) => ({
    ...site,
    subColOne: site.groups.map((group) => ({
      ...group,
      subColTwo: group.cameras,
    })),
  }));

  return { camerasData, data, isLoading };
};

export default useConvertCamerasToSubCols;
